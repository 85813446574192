import React, { useEffect, FunctionComponent } from "react";
import { navigate } from "gatsby";

// @ts-ignore
import { usePrismicPreview } from "gatsby-source-prismic";

interface PreviewPageProps {
  location: any;
}

const PreviewPage: FunctionComponent<PreviewPageProps> = ({ location }) => {
  /* Obtain data sent for previewing store and intended */
  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: "decunify-test",
    linkResolver: () => (doc: any) => {
      if (!doc || !doc.type || typeof doc.type !== "string") {
        return "/";
      }

      const defaultLang = process.env.DEFAULT_LANG || "pt";
      const lang = defaultLang === "es"
        ? (doc.lang === "es-es" && "/es")
        : (doc.lang === "en-us" && "/en") ||
        (doc.lang === "pt-pt" && "/pt") ||
        (doc.lang === "es-es" && "/es") ||
        "/pt";

      if (doc.type === "case_study") {
        return lang + "/case-studies/" + (doc.uid || "");
      }
      if (doc.type === "post") {
        return lang + "/blog/posts/" + (doc.uid || "");
      }

      if (doc.type === "solution_page") {
        return lang + "/solutions/" + (doc.uid || "");
      }

      const type:
        | "homepage"
        | "about_us_page"
        | "blog_main_page"
        | "contacts_page"
        | "cookies_page"
        | "service"
        | "services_page"
        | "solution_intro"
        | "solutions_preview"
        | "with_us_page"
        | "" = doc.type;
      if (type === "") {
        return lang + "/";
      }

      return (
        lang +
          {
            homepage: "/",
            about_us_page: "/about-us",
            blog_main_page: "/blog",
            contacts_page: "/contact-us",
            cookies_page: "/policies",
            with_us_page: "/with-us",
            service: "/",
            services_page: "/services/specialized",
            solution_intro: "/",
            solutions_preview: "/"
          }[type] || "/"
      );
    }
  });

  /* Store preview data on the browser and then Redirect to the intended path*/
  useEffect(() => {
    if (previewData && path) {
      // @ts-ignore
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [previewData, path]);

  return <div>Loading preview...</div>;
};

export default PreviewPage;
